import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { FaBox, FaStore, FaUserEdit } from "react-icons/fa";
import { MdOutlineLogout } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { logout, setCredentials } from "../slices/authSlice";
import { useGetMyOrdersQuery } from "../slices/orderApiSlice";
import { useProfileMutation } from "../slices/usersApiSlice";

const ProfileScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [activeSection, setActiveSection] = useState("orders");
  const [showOrders, setShowOrders] = useState(true); // State to control order visibility

  const { userInformation } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [updateProfile, { isLoading }] = useProfileMutation();
  const userId = userInformation?.NameIdentifier;

  const {
    data: ordersData,
    error,
    isLoading: ordersLoading,
  } = useGetMyOrdersQuery(
    { userId: userId },
    {
      skip: !showOrders,
    }
  );

  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();

  useEffect(() => {
    if (userInformation) {
      setEmail(userInformation?.email);
      setName(userInformation?.name);
    }
  }, [userInformation]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await updateProfile({ name, email }).unwrap();
      dispatch(setCredentials({ ...res }));
      toast.success("Profile updated successfully");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleMyOrdersClick = () => {
    setShowOrders(true); // Set to true to fetch orders
    setActiveSection("orders");
  };

  const handleEditUserClick = () => {
    setActiveSection("editUser");
  };

  const handleLogout = () => {
    dispatch(logout());
    toast.success("Logged out successfully");
  };

  return (
    <>
      <Container fluid className="border-bottom py-2">
        <Row className="g-0" style={{ maxWidth: "auto" }}>
          <Col xs={4} className="border-end">
            <Button
              variant="light"
              className="w-100 py-2 rounded-0 d-flex align-items-center justify-content-center gap-2"
              onClick={handleMyOrdersClick}
            >
              <FaBox size={18} />
              <span>Orders</span>
            </Button>
          </Col>

          <Col xs={4}>
            <Button
              variant="light"
              className="w-100 py-2 rounded-0 d-flex align-items-center justify-content-center gap-2"
              onClick={handleEditUserClick}
            >
              <FaUserEdit size={18} />
              <span>Profile</span>
            </Button>
          </Col>

          <Col xs={4}>
            <Button
              variant="light"
              className="w-100 py-2 rounded-0 d-flex align-items-center justify-content-center gap-2"
              style={{ color: "red" }}
              onClick={handleLogout}
            >
              <MdOutlineLogout size={18} />
              <span style={{ color: "red" }}>Logout</span>
            </Button>
          </Col>
          {userInformation?.Role !== "Seller" && (
            <Col xs={12}>
              <Button
                variant="light"
                className="w-100 py-2 rounded-0 d-flex align-items-center justify-content-center gap-2"
                as={Link}
                to="/shop/sellers"
              >
                <FaStore className="me-2" />
                Seller
              </Button>
            </Col>
          )}
        </Row>
      </Container>

      <div className="d-md-none">
        {activeSection === "editUser" && (
          <Row className="justify-content-md-center px-2">
            <Col md={12}>
              <h2>Edit User</h2>
              <Form onSubmit={submitHandler}>
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="email" className="my-3">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Button type="submit" variant="light" className="border-black">
                  {isLoading ? <Loader size="sm" /> : "Update"}
                </Button>
              </Form>
            </Col>
          </Row>
        )}

        {activeSection === "orders" && (
          <Row className="px-2 pt-2">
            <Col md={12}>
              <h2>Orders</h2>
              {ordersLoading ? (
                <Loader />
              ) : error ? (
                <Message variant="danger">
                  {error?.data?.message || error.error}
                </Message>
              ) : (
                ordersData &&
                ordersData.orders.length > 0 && (
                  <div className="order-cards">
                    {ordersData.orders.map((order) => (
                      <Card key={order?.order_id} className="mb-3">
                        <Card.Body>
                          <Row>
                            <Col md={8}>
                              <p>
                                <strong>Shop Name:</strong>
                                {order?.establishment_name
                                  ? order?.establishment_name
                                  : "Super Mart"}
                              </p>
                              <p>
                                <strong>Total Price:</strong> ₹
                                {order?.total_price}
                              </p>
                              <p>
                                <strong>Total Items: </strong>
                                {order?.orderLists?.length}
                              </p>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    ))}
                  </div>
                )
              )}
              <h2>
                {ordersData && ordersData.orders.length < 0 && "No Orders"}
              </h2>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default ProfileScreen;
