import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import BottomNavbar from "./components/BottomNavbar";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LogoLoader from "./components/LogoLoader";
import { SearchDropDown } from "./components/SearchDropDown";
function App() {
  const location = useLocation();
  const searchString = location.pathname.split("/");
  const showSearch = ["/", "/cart"];
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    // Simulate loading time or fetch data before hiding the loader
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after 2 seconds (or when the app is ready)
    }, 2000);

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, [location.pathname]);

  return (
    <div className="App">
      {loading && <LogoLoader />} {/* Show loader if loading state is true */}
      <Header />
      {showSearch?.includes(location.pathname) && <SearchDropDown />}
      {searchString?.includes("search") && <SearchDropDown />}
      <main className="pb-3">
        <>
          {/* <HomeScreen /> */}
          <Outlet />
        </>
      </main>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="dark"
      />
      <BottomNavbar />
    </div>
  );
}

export default App;
