/* eslint-disable no-unused-vars */
import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import {
  useDeleteProductByIdMutation,
  useGetSellerProductsQuery,
} from "../../slices/productsApiSlice";
import { BASE_URL } from "../../constants";

const ProductListScreen = () => {
  const { userInformation } = useSelector((state) => state?.auth);
  const entityId = userInformation?.sellerID;
  const navigate = useNavigate();
  const {
    data: products,
    isLoading,
    error,
    refetch,
  } = useGetSellerProductsQuery({
    entityID: entityId,
  });

  const [deleteProduct, { isLoading: loadingDeleteProduct }] =
    useDeleteProductByIdMutation();

  const handleCreateProduct = () => {
    navigate("/shop/createproducts");
  };

  const handleDeleteProduct = async (id) => {
    try {
      if (window.confirm("Are you sure you want to delete this product?")) {
        await deleteProduct(id).unwrap();
        toast.success("Product deleted successfully");
        refetch();
      }
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  const handleSingleProductRedirection = (id) => {
    navigate(`/product/${id}`);
  };

  return (
    <div>
      {/* Shop Info and Management Buttons */}
      <Row className="p-2 w-100">
        <Col xs={6} className="d-flex align-items-center">
          <h1 className="mb-0">Products</h1>
        </Col>
        <Col xs={6} className="d-flex align-items-center justify-content-end">
          <Button
            variant="light"
            onClick={handleCreateProduct}
            className="d-flex align-items-center gap-2"
            style={{
              border: "1px solid black",
            }}
          >
            <FaPlus size={15} />
            <span>Add</span>
          </Button>
        </Col>
      </Row>

      {/* Product List Section */}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <div className="p-2">
          {products?.data?.products?.length > 0 ? (
            products?.data?.products?.map((product) => (
              <Card
                key={product.product_id}
                className="custom-card mb-2"
                aria-label="seller-product-card"
                onMouseEnter={(e) =>
                  e.currentTarget.classList.add("hover-scale")
                }
                onMouseLeave={(e) =>
                  e.currentTarget.classList.remove("hover-scale")
                }
              >
                <div
                  onClick={() =>
                    handleSingleProductRedirection(product?.product_id)
                  }
                >
                  {/* Product Image */}
                  <div style={{ flex: "0 0 150px", overflow: "hidden" }}>
                    <img
                      src={
                        `${BASE_URL}/api/Files/download/${product?.imageName}` ||
                        "https://via.placeholder.com/150"
                      }
                      alt={product.productName}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>

                  {/* Product Details */}
                  <div
                    style={{
                      flex: "1",
                      padding: "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <h5 style={{ fontWeight: "600", fontSize: "1.2rem" }}>
                        {product?.productName}
                      </h5>
                      <p>
                        Price: {`${product.currency_mode} ${product.price}`}
                      </p>
                      <p>Category: {product.category}</p>
                      <p>
                        Stock Status:
                        <span
                          style={{
                            color: product.stock_quantity > 0 ? "green" : "red",
                            fontWeight: "bold",
                            marginLeft: "5px",
                          }}
                        >
                          {product.stock_quantity > 0
                            ? "In Stock"
                            : "Out of Stock"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                {/* Action Buttons */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderTop: "1px solid #eee",
                  }}
                  className="p-2"
                >
                  <Button
                    variant="outline-danger"
                    onClick={() => handleDeleteProduct(product.product_id)}
                    style={{ padding: "5px 10px", fontSize: "0.85rem" }}
                  >
                    <FaTrash /> Delete
                  </Button>

                  <Button
                    variant="outline-primary"
                    style={{ padding: "5px 10px", fontSize: "0.85rem" }}
                    onClick={() =>
                      navigate(`/shop/editproduct/${product.product_id}`)
                    }
                  >
                    <FaEdit /> Edit
                  </Button>
                </div>
              </Card>
            ))
          ) : (
            <Message variant="info">No products found.</Message>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductListScreen;
