import { Card, Col, Image, ListGroup, Row } from "react-bootstrap";
// eslint-disable-next-line no-unused-vars
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useGetMyOrderByOrderIdQuery } from "../slices/orderApiSlice";
import { BASE_URL } from "../constants";

const OrderScreen = () => {
  const { id: orderId } = useParams();
  const {
    data: order,
    isLoading,
    error,
  } = useGetMyOrderByOrderIdQuery({ order_id: orderId });

  const itemsPrice = order?.orderLists?.reduce((acc, item) => {
    return acc + item.price * item.quantity;
  }, 0);

  return isLoading ? (
    <Loader />
  ) : error ? (
    <Message variant={"danger"}>Order Not Found</Message>
  ) : (
    <>
      <h6 className="m-2">Order {orderId}</h6>
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <p>
                <strong>Name: </strong> {order?.buyerName}
              </p>
              <p>
                <strong>Phone Number: </strong> {order?.buyerNumber}
              </p>
              <p>
                <strong>Email: </strong>{" "}
                <a href={`mailto:${order?.buyerEmail}`}>{order?.buyerEmail}</a>
              </p>
              <p>
                <strong>Address: </strong>
                {order?.shipping_address}
              </p>
              {/* {order?.isDelivered ? (
                <Message variant="success">
                  Delivered on {order?.deliveredAt}
                </Message>
              ) : (
                <Message variant="danger">Not Delivered</Message>
              )} */}
            </ListGroup.Item>
            <ListGroup.Item>
              <p>
                <strong>Payment Method: </strong>
                {order?.paymentMethod ?? "COD"}
              </p>
              {/* {order?.isPaid ? (
                <Message variant="success">Paid on {order?.paidAt}</Message>
              ) : (
                <Message variant="danger">Not Paid</Message>
              )} */}
            </ListGroup.Item>
            <ListGroup.Item>
              <h6>Order Items</h6>
              {order?.orderItems?.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                <ListGroup variant="flush">
                  {order?.orderLists?.map((item, index) => (
                    <ListGroup.Item className="py-3" key={index}>
                      <Row className="align-items-center">
                        <Col xs={3} md={2}>
                          <Image
                            src={
                              `${BASE_URL}/api/Files/download/${item?.imageName}` ||
                              "https://via.placeholder.com/150"
                            }
                            alt={item.imageName}
                            fluid
                            rounded
                            width={60}
                            height={60}
                          />
                        </Col>
                        <Col xs={9} md={10}>
                          <div className="d-flex flex-column">
                            <Link
                              to={`/product/${item.productID}`}
                              className="mb-2 text-decoration-none text-dark"
                            >
                              {item.name ?? "Product Name"}
                            </Link>
                            <div>
                              {item.quantity} x Rs. {item.price} = Rs.{" "}
                              {item.quantity * item.price}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Items</Col>
                  <Col>Rs. {itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              {/* <ListGroup.Item>
                <Row>
                  <Col>Shipping</Col>
                  <Col>Rs. {order?.shippingPrice ?? 0}</Col>
                </Row>
              </ListGroup.Item> */}
              {/* <ListGroup.Item>
                <Row>
                  <Col>Tax</Col>
                  <Col>Rs. {order?.taxPrice}</Col>
                </Row>
              </ListGroup.Item> */}
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                  <Col>Rs. {Number(order?.total_price)}</Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OrderScreen;
