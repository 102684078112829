import React from "react";
import { Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import CategoriesTab from "../components/CategoriesTab";
import Loader from "../components/Loader";
import { useGetProductsByNameQuery } from "../slices/productsApiSlice";
import ProductListPage from "./SearchProductScreen";

export default function HomeScreen() {
  const { keyword } = useParams();
  const shouldSkip = !keyword || keyword.trim() === "";

  // USE ERROR STATE FOR EVERY LISTING TYPE QUERIES
  const { data, isLoading, error } = useGetProductsByNameQuery(
    { search: keyword },
    { skip: shouldSkip }
  );

  const products = data && data?.data?.products;

  return (
    <Container>
      {!keyword ? (
        <CategoriesTab />
      ) : (
        <Link to={`/`} className="btn btn-light mb-4 mt-2">
          Go Back
        </Link>
      )}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <p>No products found.</p>
      ) : (
        <>
          {keyword && products?.length > 0 && (
            <ProductListPage products={products} keyword={keyword} />
          )}
        </>
      )}
    </Container>
  );
}
