// Pricing.js
import { Form, Row, Col } from "react-bootstrap";
import { INR_CHAR } from "../../constants";

const Pricing = ({
  price,
  setPrice,
  currency,
  setCurrency,
  stock,
  setStock,
  unitofMeasure,
  unitofQuantity,
  setUnitofMeasure,
  setUnitofQuantity,
}) => {
  return (
    <div className="pricing">
      <Row>
        <Col xs={12} md={4} className="mb-3">
          <Form.Group>
            <Form.Label>Price: {INR_CHAR}</Form.Label>
            <Form.Control
              type="number"
              id="price"
              min={1}
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col className="mb-3">
          <Form.Group>
            <Form.Label>
              Rate: {INR_CHAR} {price} / {unitofQuantity} {unitofMeasure}
            </Form.Label>
            <Form.Control
              type="number"
              id="uam_quantity"
              min={1}
              max={10000}
              value={unitofQuantity}
              onChange={(e) => setUnitofQuantity(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Measure</Form.Label>
            <Form.Control
              id="measure"
              min={1}
              value={unitofMeasure}
              onChange={(e) => setUnitofMeasure(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={4} className="mb-3">
          <Form.Group>
            <Form.Label>Stock</Form.Label>
            <Form.Control
              type="number"
              id="stock"
              min={1}
              value={stock}
              onChange={(e) => setStock(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default Pricing;
