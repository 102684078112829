/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { useGetOrderBySellerIdMutation } from "../../slices/orderApiSlice";
import { FaBox, FaProductHunt, FaUserEdit } from "react-icons/fa";
import ProductListScreen from "./ProductListScreen";
import { LinkContainer } from "react-router-bootstrap";
import { formatDate } from "../../utils/helper";

const Shop = () => {
  const { userInformation } = useSelector((state) => state?.auth);
  const sellerId = userInformation && userInformation?.sellerID;
  const [getOrder, { data, isLoading, isError }] =
    useGetOrderBySellerIdMutation();
  useEffect(() => {
    const getData = async () => {
      await getOrder({
        entity_id: sellerId,
      });
    };
    getData();
  }, [getOrder, sellerId]);
  const orderData = data && data?.orders;

  // State for filters
  const [statusFilter, setStatusFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [activeSection, setActiveSection] = useState("orders");
  // Filtered orders based on status and date
  const filteredOrders = orderData?.filter((order) => {
    return (
      (!statusFilter || order?.status === statusFilter) &&
      (!dateFilter || order?.date === dateFilter)
    );
  });
  const handleMyOrdersClick = () => {
    setActiveSection("orders");
  };
  const handleEditUserClick = () => {};
  return (
    <Container className="my-2">
      {/* Shop Info and Management Buttons */}
      <Row className="g-0" style={{ maxWidth: "auto" }}>
        <Col xs={4} className="border-end">
          <Button
            variant="light"
            className="w-100  rounded-0 d-flex align-items-center justify-content-center gap-2"
            onClick={handleMyOrdersClick}
          >
            <FaBox size={18} />
            <span>Orders</span>
          </Button>
        </Col>

        <Col xs={4} className="border-end">
          <Button
            variant="light"
            className="w-100 rounded-0 d-flex align-items-center justify-content-center gap-2"
            onClick={() => setActiveSection("products")}
          >
            <FaProductHunt size={18} />
            <span>Products</span>
          </Button>
        </Col>
        <Col xs={4}>
          <Button
            variant="light"
            className="w-100 rounded-0 d-flex align-items-center justify-content-center gap-2"
            onClick={handleEditUserClick}
          >
            <FaUserEdit size={18} />
            <span>Shop</span>
          </Button>
        </Col>
      </Row>

      {activeSection === "products" && <ProductListScreen />}
      {/* Filter Section */}

      {isLoading ? (
        <Loader />
      ) : isError ? (
        <Message variant={"danger"}>something went wrong</Message>
      ) : (
        <>
          {activeSection === "orders" && filteredOrders?.length > 0 ? (
            filteredOrders?.map((order) => (
              <LinkContainer
                to={`/order/${order?.order_id}`}
                key={order?.order_id}
              >
                <Card
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "20px",
                    border: "1px solid #e0e0e0",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    transition: "transform 0.3s ease-in-out",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    cursor: "pointer",
                    overflow: "hidden",
                  }}
                  className="mt-2"
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.transform = "scale(1.02)")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.transform = "scale(1)")
                  }
                >
                  <div
                    style={{
                      flex: "1",
                      padding: "10px 20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                    className="cursor-pointer"
                  >
                    <div>
                      {order?.quantity && (
                        <p className="order_detail_p">
                          Quantity: {order?.quantity}
                        </p>
                      )}
                      <p className="order_detail_p">
                        Overall Price: ₹{order?.total_price}
                      </p>
                      {order?.buyerPhone && (
                        <p className="order_detail_p">
                          Buyer Phone: {order?.buyerPhone}
                        </p>
                      )}
                      <p className="order_detail_p">
                        Payment Mode: {order?.mode_of_payment || "COD"}
                      </p>
                      <p className="order_detail_p">
                        Payment Mode: {order?.shipping_address}
                      </p>
                      {order?.added_on && (
                        <p className="order_detail_p">
                          Date: {formatDate(order?.added_on, "dd/mm/yyyy")}
                        </p>
                      )}
                    </div>
                  </div>
                </Card>
              </LinkContainer>
            ))
          ) : (
            <>
              {activeSection === "orders" && (
                <p className="text-center mt-2">No Orders</p>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default Shop;
