import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  ListGroup,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { addToCart, removeFromCart } from "../slices/cartSlice"; // Adjust the import path as needed
import {
  useDeleteCartQuantityMutation,
  useGetCartByUserIdQuery,
  useUpdateCartQuantityMutation,
} from "../slices/cartApiSlice";
import { handleApiRequest } from "../utils/helper";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL } from "../constants";
import { FaTrash } from "react-icons/fa";

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInformation } = useSelector((state) => state?.auth);
  const { cartItems } = useSelector((state) => state.cart);
  const user_id = userInformation && userInformation?.NameIdentifier;
  const { data, refetch, isSuccess } = useGetCartByUserIdQuery(user_id, {
    skip: !user_id, // Skip the query if user_id is falsy
  });
  const cartData = data && data?.data?.carts;
  const [updateQuantity] = useUpdateCartQuantityMutation();
  const [deleteCart] = useDeleteCartQuantityMutation();
  const updatedCart =
    cartData &&
    Object?.values(
      cartData?.reduce((acc, item) => {
        if (!acc[item?.product_id]) {
          acc[item?.product_id] = { ...item };
        } else {
          acc[item?.product_id].quantity += item?.quantity;
        }
        return acc;
      }, {})
    );
  const newCartData = updatedCart ?? cartItems;
  // Calculate total, GST, and discounts
  useEffect(() => {
    if (user_id && isSuccess) {
      refetch();
    }
  }, [refetch, user_id, isSuccess]);

  const itemsPrice =
    newCartData &&
    newCartData.reduce(
      (acc, item) => acc + (item?.price ?? item?.total_price) * item?.quantity,
      0
    );
  // const gst = itemsPrice * 0.18;
  // const discount = itemsPrice > 1000 ? 100 : 0;
  const total_price = itemsPrice;

  const handleRemoveFromCart = async (product_id) => {
    try {
      if (userInformation) {
        // Call API to delete cart item
        await handleApiRequest(() =>
          deleteCart({
            user_id,
            product_id: product_id.toString(),
          }).unwrap()
        );
        // Dispatch action to remove item from Redux store
        dispatch(removeFromCart(product_id));
      } else {
        // Directly dispatch action if user information is not present
        dispatch(removeFromCart(product_id));
      }

      // Ensure refetch only runs if the query was started
      if (isSuccess && typeof refetch === "function") {
        const updatedCartData = await refetch();
        if (updatedCartData?.error) {
          console.error("Error refetching cart data:", updatedCartData.error);
        }
      } else {
        console.warn("Cannot refetch as the query is not active.");
      }
    } catch (error) {
      toast.error(error?.message || "An error occurred.");
    }
  };

  const handleQuantityChange = async (item, quantity) => {
    const product_id = item.product_id;
    try {
      if (userInformation) {
        await handleApiRequest(() =>
          updateQuantity({
            user_id: user_id,
            product_id,
            entity_id: item?.entity_id,
            quantity: Number(quantity),
          }).unwrap()
        );
      }
      refetch();
      dispatch(addToCart({ ...item, quantity: Number(quantity) }));
    } catch (error) {
      console.log("error", error);
    }
  };

  const checkOutHandler = () => {
    navigate(`/shipping`);
  };

  return (
    <Container className="py-4">
      {newCartData?.length > 0 && (
        <Row>
          <h2 className="mb-4">Shopping Cart</h2>
          <Col md={8}>
            <ListGroup variant="flush">
              {newCartData?.map((item) => (
                <ListGroup.Item
                  key={item?.product_id}
                  className="d-flex align-items-center mb-3 p-3"
                  style={{ border: "1px solid #ddd", borderRadius: "8px" }}
                >
                  {/* Placeholder Image */}
                  <img
                    src={`${BASE_URL}/${item?.imageName}`}
                    alt={item?.productName}
                    className="img-fluid rounded"
                    style={{
                      width: "80px",
                      height: "80px",
                      objectFit: "cover",
                    }}
                  />
                  {/* Product Details */}
                  <div className="ms-3 flex-grow-1">
                    <h5 className="mb-1">{item?.productName ?? ""}</h5>
                    <p className="mb-1 text-muted">
                      ₹{item?.price ?? item.total_price} x {item?.quantity} = ₹
                      {(
                        item?.price ?? item.total_price * item?.quantity
                      )?.toFixed(2)}
                    </p>
                  </div>
                  {/* Quantity Selector */}
                  <Form.Control
                    as="select"
                    value={item?.quantity}
                    onChange={(e) => handleQuantityChange(item, e.target.value)}
                    className="ms-auto me-3"
                    style={{ width: "60px" }}
                  >
                    {[...Array(10).keys()]?.map((x) => (
                      <option
                        key={x + 1}
                        value={x + 1}
                        selected={item?.quantity}
                      >
                        {x + 1}
                      </option>
                    ))}
                  </Form.Control>
                  {/* Remove Button */}
                  <Button
                    variant="outline-danger"
                    onClick={() =>
                      handleRemoveFromCart(item?.product_id, item?.entity_id)
                    }
                  >
                    <FaTrash />
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>

          {/* Summary Section */}
          <Col md={4}>
            <Card>
              <Card.Header className="bg-primary text-white">
                Price Summary
              </Card.Header>
              <Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col>Subtotal:</Col>
                      <Col>₹{itemsPrice?.toFixed(2)}</Col>
                    </Row>
                  </ListGroup.Item>
                  {/* <ListGroup.Item>
                    <Row>
                      <Col>GST (18%):</Col>
                      <Col>₹{gst?.toFixed(2)}</Col>
                    </Row>
                  </ListGroup.Item> 
                  <ListGroup.Item>
                    <Row>
                      <Col>Discount:</Col>
                      <Col className="text-danger">
                        -₹{discount?.toFixed(2)}
                      </Col>
                    </Row>
                  </ListGroup.Item> */}
                  <ListGroup.Item>
                    <Row>
                      <Col>Total:</Col>
                      <Col>
                        <strong>₹{total_price?.toFixed(2)}</strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
                {/* Checkout Button */}
                <Button
                  variant="success"
                  className="w-100 mt-3"
                  disabled={cartItems.length === 0}
                  onClick={() => checkOutHandler()}
                >
                  Checkout
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Cart;
