import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, Row, Col, ListGroup } from "react-bootstrap";
import { useGetOrderByIdForSellerMutation } from "../../slices/orderApiSlice";
import { BASE_URL } from "../../constants";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
const OrderDetail = () => {
  const { orderId } = useParams();
  const [getOrderForSeller, { data, isLoading, isError }] =
    useGetOrderByIdForSellerMutation();

  useEffect(() => {
    const getData = async () => {
      await getOrderForSeller({
        orderID: orderId,
      });
    };
    getData();
  }, [getOrderForSeller, orderId]);

  if (isLoading) return <Loader />;
  if (isError)
    return <Message variant={"danger"}>Something went wrong</Message>;
  return (
    <div
      style={{
        maxWidth: "100%",
        margin: "0",
        padding: "0",
        backgroundColor: "#eaeaea",
      }}
    >
      <Card
        style={{
          border: "none",
          borderRadius: "12px",
          overflow: "hidden",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
          margin: "20px",
          backgroundColor: "#fff",
        }}
      >
        <Card.Header
          style={{
            backgroundColor: "whitesmoke", // Blue background
            textAlign: "center",
            padding: "5px",
            fontSize: "2rem",
            fontWeight: "400",
            letterSpacing: "1px",
          }}
        >
          Order {orderId?.slice(0, 12)}...
        </Card.Header>
        <Card.Body>
          <div
            style={{
              marginTop: "40px",
              padding: "5px",
              borderRadius: "8px",
              backgroundColor: "#f8f9fa",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h4 style={{ marginBottom: "10px", color: "#0056b3" }}>
              Buyer Information
            </h4>
            <ListGroup>
              <ListGroup.Item>
                <strong>Name:</strong>
                {data?.buyerName}
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Email:</strong>
                {data?.buyerEmail}
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Phone:</strong>
                {data?.buyerNumber}
              </ListGroup.Item>
            </ListGroup>
          </div>

          <div
            style={{
              marginTop: "20px",
              padding: "20px",
              borderRadius: "8px",
              backgroundColor: "#f8f9fa",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h4 style={{ marginBottom: "10px", color: "#0056b3" }}>
              Shipping Address
            </h4>
            <p style={{ fontSize: "1rem" }}>{data?.shipping_address}</p>
          </div>

          <Card className="p-2 mt-4">
            {data?.orderLists?.length > 0 ? (
              data?.orderLists.map((order, index) => (
                <div key={order?.orderListID || index} className="mb-3">
                  <Row className="g-3 align-items-center">
                    <Col xs={4} sm={3} md={2}>
                      <Card.Img
                        src={`${BASE_URL}/${order?.imageName}`}
                        alt={order?.productName}
                        className="w-100 h-auto"
                        style={{
                          height: "150px",
                          objectFit: "cover",
                          borderRadius: "8px",
                        }}
                        onError={(e) => {
                          e.target.src =
                            "https://plus.unsplash.com/premium_photo-1679517155620-8048e22078b1";
                        }}
                        loading="lazy"
                      />
                    </Col>

                    <Col xs={8} sm={9} md={10}>
                      <h6 className="fw-bold">
                        {order?.productName || "Product Name Not Available"}
                      </h6>
                      <p className="mb-1">Price: ₹{order?.price || 0}</p>
                      <p className="mb-1">Quantity: {order?.quantity || 0}</p>
                      <h6 className="fw-bold text-end">
                        ₹{order?.price * order?.quantity || 0}
                      </h6>
                    </Col>
                  </Row>
                  <hr className="my-3" />
                </div>
              ))
            ) : (
              <p>No orders available</p>
            )}

            <Row>
              <Col xs={6}>
                <strong>Total:</strong>
              </Col>
              <Col xs={6} className="text-end">
                <strong>
                  ₹
                  {data?.orderLists?.reduce(
                    (total, order) =>
                      total + (order.price * order.quantity || 0),
                    0
                  )}
                </strong>
              </Col>
            </Row>
          </Card>
        </Card.Body>
      </Card>
    </div>
  );
};

export default OrderDetail;
